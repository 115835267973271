<template>
  <v-card class="centrado imgproceesing">
    <svg
      viewBox="0 0 856 611"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-proceesing"
    >
      <g id="Group 1">
        <g id="undraw_data_processing_yrrv 1" clip-path="url(#clip0_1_61)">
          <path
            id="Vector"
            d="M39.5359 124.357C47.0638 124.357 53.1664 118.255 53.1664 110.727C53.1664 103.199 47.0638 97.096 39.5359 97.096C32.0079 97.096 25.9053 103.199 25.9053 110.727C25.9053 118.255 32.0079 124.357 39.5359 124.357Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_2"
            d="M80.4276 124.357C87.9555 124.357 94.0581 118.255 94.0581 110.727C94.0581 103.199 87.9555 97.096 80.4276 97.096C72.8996 97.096 66.797 103.199 66.797 110.727C66.797 118.255 72.8996 124.357 80.4276 124.357Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_3"
            d="M121.319 124.357C128.847 124.357 134.95 118.255 134.95 110.727C134.95 103.199 128.847 97.096 121.319 97.096C113.791 97.096 107.689 103.199 107.689 110.727C107.689 118.255 113.791 124.357 121.319 124.357Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_4"
            d="M162.211 124.357C169.739 124.357 175.842 118.255 175.842 110.727C175.842 103.199 169.739 97.096 162.211 97.096C154.683 97.096 148.58 103.199 148.58 110.727C148.58 118.255 154.683 124.357 162.211 124.357Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_5"
            d="M203.103 124.357C210.631 124.357 216.733 118.255 216.733 110.727C216.733 103.199 210.631 97.096 203.103 97.096C195.575 97.096 189.472 103.199 189.472 110.727C189.472 118.255 195.575 124.357 203.103 124.357Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_6"
            d="M243.994 124.357C251.522 124.357 257.625 118.255 257.625 110.727C257.625 103.199 251.522 97.096 243.994 97.096C236.466 97.096 230.364 103.199 230.364 110.727C230.364 118.255 236.466 124.357 243.994 124.357Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_7"
            d="M284.886 124.357C292.414 124.357 298.517 118.255 298.517 110.727C298.517 103.199 292.414 97.096 284.886 97.096C277.358 97.096 271.255 103.199 271.255 110.727C271.255 118.255 277.358 124.357 284.886 124.357Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_8"
            d="M316.384 127.326H8.03828C6.56768 127.324 5.1578 126.739 4.11792 125.699C3.07804 124.659 2.49309 123.249 2.49136 121.779V99.6748C2.49309 98.2042 3.07804 96.7943 4.11792 95.7544C5.1578 94.7146 6.56768 94.1296 8.03828 94.1279H316.384C317.854 94.1296 319.264 94.7146 320.304 95.7544C321.344 96.7943 321.929 98.2042 321.931 99.6748V121.779C321.929 123.249 321.344 124.659 320.304 125.699C319.264 126.739 317.854 127.324 316.384 127.326ZM8.03828 96.3467C7.1559 96.3476 6.30993 96.6986 5.68599 97.3225C5.06205 97.9465 4.71109 98.7924 4.71013 99.6748V121.779C4.71109 122.661 5.06205 123.507 5.68599 124.131C6.30993 124.755 7.1559 125.106 8.03828 125.107H316.384C317.266 125.106 318.112 124.755 318.736 124.131C319.36 123.507 319.711 122.661 319.712 121.779V99.6748C319.711 98.7924 319.36 97.9465 318.736 97.3225C318.112 96.6986 317.266 96.3476 316.384 96.3466L8.03828 96.3467Z"
            fill="#E6E6E6"
          />
          <g id="Document">
            <path
              id="Vector_9"
              d="M74.0261 3.18704C74.8711 3.18801 75.6812 3.5241 76.2786 4.12157C76.8761 4.71904 77.2122 5.52909 77.2131 6.37404V75.4367C77.2122 76.2816 76.8761 77.0917 76.2786 77.6891C75.6812 78.2866 74.8711 78.6227 74.0261 78.6237H6.37415C5.5292 78.6227 4.71913 78.2866 4.12166 77.6891C3.52419 77.0917 3.1881 76.2816 3.18713 75.4367V6.3741C3.1881 5.52915 3.52419 4.71908 4.12166 4.12161C4.71913 3.52414 5.5292 3.18805 6.37415 3.18709L74.0261 3.18704ZM74.0261 4.57764e-05H6.37415C4.68363 4.57764e-05 3.06235 0.671598 1.86697 1.86697C0.671598 3.06235 3.05176e-05 4.68363 3.05176e-05 6.37415V75.4367C3.05176e-05 77.1272 0.671598 78.7485 1.86697 79.9438C3.06235 81.1392 4.68363 81.8108 6.37415 81.8108H74.0261C75.7167 81.8108 77.3379 81.1392 78.5333 79.9438C79.7287 78.7485 80.4002 77.1272 80.4002 75.4367V6.3741C80.4002 4.68358 79.7287 3.0623 78.5333 1.86693C77.3379 0.671553 75.7167 4.84285e-05 74.0261 4.57764e-05Z"
              fill="#E6E6E6"
            />
            <path
              id="Vector_10"
              d="M63.4739 21.3406H18.337C18.1269 21.3419 17.9186 21.3016 17.7242 21.2221C17.5297 21.1426 17.3529 21.0254 17.2039 20.8773C17.0549 20.7293 16.9366 20.5531 16.8559 20.3592C16.7752 20.1652 16.7337 19.9572 16.7337 19.7471C16.7337 19.537 16.7752 19.329 16.8559 19.135C16.9366 18.9411 17.0549 18.7649 17.2039 18.6169C17.3529 18.4688 17.5297 18.3516 17.7242 18.2721C17.9186 18.1926 18.1269 18.1523 18.337 18.1536H63.4739C63.684 18.1523 63.8922 18.1926 64.0867 18.2721C64.2812 18.3516 64.458 18.4688 64.607 18.6169C64.756 18.7649 64.8743 18.9411 64.955 19.135C65.0357 19.329 65.0772 19.537 65.0772 19.7471C65.0772 19.9572 65.0357 20.1652 64.955 20.3592C64.8743 20.5531 64.756 20.7293 64.607 20.8773C64.458 21.0254 64.2812 21.1426 64.0867 21.2221C63.8922 21.3016 63.684 21.3419 63.4739 21.3406Z"
              fill="#F50057"
            />
            <path
              id="Vector_11"
              d="M63.4739 32.6252H18.337C18.1269 32.6265 17.9186 32.5862 17.7242 32.5067C17.5297 32.4272 17.3529 32.31 17.2039 32.1619C17.0549 32.0138 16.9366 31.8377 16.8559 31.6438C16.7752 31.4498 16.7337 31.2418 16.7337 31.0317C16.7337 30.8216 16.7752 30.6136 16.8559 30.4196C16.9366 30.2256 17.0549 30.0496 17.2039 29.9015C17.3529 29.7534 17.5297 29.6362 17.7242 29.5567C17.9186 29.4772 18.1269 29.4369 18.337 29.4382H63.4739C63.684 29.4369 63.8922 29.4772 64.0867 29.5567C64.2812 29.6362 64.458 29.7534 64.607 29.9015C64.756 30.0496 64.8743 30.2256 64.955 30.4196C65.0357 30.6136 65.0772 30.8216 65.0772 31.0317C65.0772 31.2418 65.0357 31.4498 64.955 31.6438C64.8743 31.8377 64.756 32.0138 64.607 32.1619C64.458 32.31 64.2812 32.4272 64.0867 32.5067C63.8922 32.5862 63.684 32.6265 63.4739 32.6252Z"
              fill="#F50057"
            />
            <path
              id="Vector_12"
              d="M63.4739 43.9091H18.337C18.1269 43.9104 17.9186 43.8701 17.7242 43.7906C17.5297 43.7111 17.3529 43.5939 17.2039 43.4458C17.0549 43.2977 16.9366 43.1216 16.8559 42.9276C16.7752 42.7337 16.7337 42.5257 16.7337 42.3156C16.7337 42.1055 16.7752 41.8975 16.8559 41.7035C16.9366 41.5095 17.0549 41.3334 17.2039 41.1853C17.3529 41.0372 17.5297 40.92 17.7242 40.8405C17.9186 40.761 18.1269 40.7208 18.337 40.7221H63.4739C63.684 40.7208 63.8922 40.761 64.0867 40.8405C64.2812 40.92 64.458 41.0372 64.607 41.1853C64.756 41.3334 64.8743 41.5095 64.955 41.7035C65.0357 41.8975 65.0772 42.1055 65.0772 42.3156C65.0772 42.5257 65.0357 42.7337 64.955 42.9276C64.8743 43.1216 64.756 43.2977 64.607 43.4458C64.458 43.5939 64.2812 43.7111 64.0867 43.7906C63.8922 43.8701 63.684 43.9104 63.4739 43.9091Z"
              fill="#F50057"
            />
            <path
              id="Vector_13"
              d="M63.4739 55.1937H18.337C18.1269 55.195 17.9186 55.1547 17.7242 55.0752C17.5297 54.9957 17.3529 54.8785 17.2039 54.7304C17.0549 54.5823 16.9366 54.4062 16.8559 54.2122C16.7752 54.0183 16.7337 53.8103 16.7337 53.6002C16.7337 53.3901 16.7752 53.1821 16.8559 52.9881C16.9366 52.7941 17.0549 52.618 17.2039 52.4699C17.3529 52.3218 17.5297 52.2047 17.7242 52.1252C17.9186 52.0456 18.1269 52.0054 18.337 52.0067H63.4739C63.684 52.0054 63.8922 52.0456 64.0867 52.1252C64.2812 52.2047 64.458 52.3218 64.607 52.4699C64.756 52.618 64.8743 52.7941 64.955 52.9881C65.0357 53.1821 65.0772 53.3901 65.0772 53.6002C65.0772 53.8103 65.0357 54.0183 64.955 54.2122C64.8743 54.4062 64.756 54.5823 64.607 54.7304C64.458 54.8785 64.2812 54.9957 64.0867 55.0752C63.8922 55.1547 63.684 55.195 63.4739 55.1937Z"
              fill="#F50057"
            />
            <path
              id="Vector_14"
              d="M63.4739 66.4783H18.337C17.9143 66.4783 17.509 66.3104 17.2102 66.0116C16.9113 65.7127 16.7434 65.3074 16.7434 64.8848C16.7434 64.4621 16.9113 64.0568 17.2102 63.758C17.509 63.4591 17.9143 63.2912 18.337 63.2912H63.4739C63.8965 63.2912 64.3018 63.4591 64.6007 63.758C64.8995 64.0568 65.0674 64.4621 65.0674 64.8848C65.0674 65.3074 64.8995 65.7127 64.6007 66.0116C64.3018 66.3104 63.8965 66.4783 63.4739 66.4783Z"
              fill="#F50057"
            />
          </g>
          <path
            id="Vector_15"
            d="M855.418 607.775H156.106V610.775H855.418V607.775Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_16"
            d="M562.14 603.085H448.421L452.276 552.973H558.285L562.14 603.085Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_17"
            d="M569.849 597.303H442.639V608.868H569.849V597.303Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_18"
            d="M723.08 230.128H288.444C285.377 230.128 282.436 231.346 280.267 233.515C278.098 235.684 276.88 238.625 276.88 241.692V506.714H734.645V241.692C734.645 240.174 734.345 238.67 733.764 237.267C733.183 235.864 732.331 234.589 731.257 233.515C730.183 232.441 728.909 231.589 727.506 231.008C726.102 230.427 724.599 230.128 723.08 230.128Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_19"
            d="M276.88 506.714V550.081C276.88 551.6 277.179 553.104 277.76 554.507C278.341 555.91 279.193 557.185 280.267 558.259C281.341 559.333 282.616 560.184 284.019 560.766C285.422 561.347 286.926 561.646 288.444 561.646H723.08C726.147 561.646 729.089 560.428 731.257 558.259C733.426 556.09 734.644 553.148 734.645 550.081V506.714H276.88Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_20"
            d="M712.479 254.221H300.009V485.512H712.479V254.221Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_21"
            d="M506.244 545.263C512.631 545.263 517.809 540.085 517.809 533.698C517.809 527.311 512.631 522.134 506.244 522.134C499.857 522.134 494.679 527.311 494.679 533.698C494.679 540.085 499.857 545.263 506.244 545.263Z"
            fill="#3F3D56"
          />
          <path
            id="Vector_22"
            d="M312.87 263.99C314.082 263.99 315.065 263.007 315.065 261.795C315.065 260.583 314.082 259.601 312.87 259.601C311.658 259.601 310.675 260.583 310.675 261.795C310.675 263.007 311.658 263.99 312.87 263.99Z"
            fill="white"
          />
          <path
            id="Vector_23"
            d="M321.2 263.99C322.412 263.99 323.395 263.007 323.395 261.795C323.395 260.583 322.412 259.601 321.2 259.601C319.988 259.601 319.006 260.583 319.006 261.795C319.006 263.007 319.988 263.99 321.2 263.99Z"
            fill="white"
          />
          <path
            id="Vector_24"
            d="M329.531 263.99C330.743 263.99 331.725 263.007 331.725 261.795C331.725 260.583 330.743 259.601 329.531 259.601C328.319 259.601 327.336 260.583 327.336 261.795C327.336 263.007 328.319 263.99 329.531 263.99Z"
            fill="white"
          />
          <path
            id="Vector_25"
            d="M700.591 269.253H310.675V463.5H700.591V269.253Z"
            fill="white"
          />
          <path
            id="Vector_26"
            d="M336.594 298.799C336.229 298.799 335.878 298.944 335.62 299.203C335.361 299.461 335.216 299.812 335.216 300.177V369.853C335.216 370.218 335.361 370.569 335.62 370.827C335.878 371.086 336.229 371.231 336.594 371.231H417.059C417.424 371.231 417.774 371.086 418.033 370.827C418.291 370.569 418.437 370.218 418.437 369.853V300.177C418.437 299.812 418.291 299.461 418.033 299.203C417.774 298.944 417.424 298.799 417.059 298.799H336.594Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_27"
            d="M465.79 299.023C465.424 299.023 465.074 299.168 464.815 299.427C464.557 299.685 464.411 300.036 464.411 300.401V369.853C464.411 370.219 464.557 370.569 464.815 370.827C465.074 371.086 465.424 371.231 465.79 371.231H545.998C546.363 371.231 546.714 371.086 546.972 370.827C547.23 370.569 547.376 370.218 547.376 369.853V300.401C547.376 300.036 547.23 299.686 546.972 299.427C546.714 299.169 546.363 299.023 545.998 299.023H465.79Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_28"
            d="M593.631 298.295C593.265 298.295 592.915 298.441 592.657 298.699C592.398 298.958 592.253 299.308 592.253 299.673V369.853C592.253 370.218 592.398 370.569 592.657 370.827C592.915 371.086 593.265 371.231 593.631 371.231H674.673C675.038 371.231 675.388 371.086 675.647 370.827C675.905 370.569 676.05 370.218 676.051 369.853V299.673C676.05 299.308 675.905 298.958 675.647 298.699C675.388 298.441 675.038 298.295 674.673 298.295H593.631Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_29"
            d="M338.065 405.721C337.31 405.722 336.587 406.023 336.054 406.557C335.52 407.092 335.221 407.816 335.221 408.571C335.221 409.325 335.52 410.049 336.054 410.584C336.587 411.118 337.31 411.419 338.065 411.42H413.718C414.473 411.419 415.196 411.118 415.729 410.584C416.263 410.049 416.562 409.325 416.562 408.571C416.562 407.816 416.263 407.092 415.729 406.557C415.196 406.023 414.473 405.722 413.718 405.721H338.065Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_30"
            d="M338.065 419.276C337.31 419.278 336.587 419.579 336.053 420.113C335.52 420.647 335.221 421.371 335.221 422.126C335.221 422.881 335.52 423.606 336.053 424.14C336.587 424.674 337.31 424.975 338.065 424.977H372.127C372.882 424.975 373.606 424.674 374.139 424.14C374.673 423.606 374.972 422.881 374.972 422.126C374.972 421.371 374.673 420.647 374.139 420.113C373.606 419.579 372.882 419.278 372.127 419.276H338.065Z"
            fill="#F50057"
          />
          <path
            id="Vector_31"
            d="M468.649 405.721C467.894 405.722 467.171 406.023 466.638 406.557C466.104 407.092 465.805 407.816 465.805 408.571C465.805 409.325 466.104 410.049 466.638 410.584C467.171 411.118 467.894 411.419 468.649 411.42H544.302C545.057 411.419 545.78 411.118 546.313 410.584C546.847 410.049 547.146 409.325 547.146 408.571C547.146 407.816 546.847 407.092 546.313 406.557C545.78 406.023 545.057 405.722 544.302 405.721H468.649Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_32"
            d="M468.649 419.276C467.894 419.278 467.171 419.579 466.637 420.113C466.104 420.647 465.805 421.371 465.805 422.126C465.805 422.881 466.104 423.606 466.637 424.14C467.171 424.674 467.894 424.975 468.649 424.977H502.712C503.467 424.975 504.19 424.674 504.724 424.14C505.257 423.606 505.557 422.881 505.557 422.126C505.557 421.371 505.257 420.647 504.724 420.113C504.19 419.579 503.467 419.278 502.712 419.276H468.649Z"
            fill="#F50057"
          />
          <path
            id="Vector_33"
            d="M595.97 405.721C595.215 405.722 594.491 406.023 593.958 406.557C593.425 407.092 593.125 407.816 593.125 408.571C593.125 409.325 593.425 410.049 593.958 410.584C594.491 411.118 595.215 411.419 595.97 411.42H671.622C672.377 411.419 673.1 411.118 673.634 410.584C674.167 410.049 674.466 409.325 674.466 408.571C674.466 407.816 674.167 407.092 673.634 406.557C673.1 406.023 672.377 405.722 671.622 405.721H595.97Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_34"
            d="M338.065 391.721C337.31 391.722 336.587 392.023 336.054 392.557C335.52 393.092 335.221 393.816 335.221 394.571C335.221 395.325 335.52 396.049 336.054 396.584C336.587 397.118 337.31 397.419 338.065 397.42H413.718C414.473 397.419 415.196 397.118 415.729 396.584C416.263 396.049 416.562 395.325 416.562 394.571C416.562 393.816 416.263 393.092 415.729 392.557C415.196 392.023 414.473 391.722 413.718 391.721H338.065Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_35"
            d="M468.649 391.721C467.894 391.722 467.171 392.023 466.638 392.557C466.104 393.092 465.805 393.816 465.805 394.571C465.805 395.325 466.104 396.049 466.638 396.584C467.171 397.118 467.894 397.419 468.649 397.42H544.302C545.057 397.419 545.78 397.118 546.313 396.584C546.847 396.049 547.146 395.325 547.146 394.571C547.146 393.816 546.847 393.092 546.313 392.557C545.78 392.023 545.057 391.722 544.302 391.721H468.649Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_36"
            d="M595.97 391.721C595.215 391.722 594.491 392.023 593.958 392.557C593.425 393.092 593.125 393.816 593.125 394.571C593.125 395.325 593.425 396.049 593.958 396.584C594.491 397.118 595.215 397.419 595.97 397.42H671.622C672.377 397.419 673.1 397.118 673.634 396.584C674.167 396.049 674.466 395.325 674.466 394.571C674.466 393.816 674.167 393.092 673.634 392.557C673.1 392.023 672.377 391.722 671.622 391.721H595.97Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_37"
            d="M595.97 419.276C595.215 419.278 594.491 419.579 593.958 420.113C593.424 420.647 593.125 421.371 593.125 422.126C593.125 422.881 593.424 423.606 593.958 424.14C594.491 424.674 595.215 424.975 595.97 424.977H630.031C630.786 424.975 631.51 424.674 632.043 424.14C632.576 423.606 632.876 422.881 632.876 422.126C632.876 421.371 632.576 420.647 632.043 420.113C631.51 419.579 630.786 419.278 630.031 419.276H595.97Z"
            fill="#F50057"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_61">
          <rect width="855.418" height="610.775" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped>
.centrado {
  align-content: center !important;
  text-align: center !important;
}
.svg-proceesing {
  display: block;
  width: 70%;
  margin: auto !important;
}
.imgproceesing {
  padding: 5% 5%;
}

#Document {
  position: relative;
  animation: moverDocument 3s infinite linear;
}

@keyframes moverDocument {
  0% {
    transform: translateX(0) rotate(0);
  }
  /* 25% {
    transform: translateX(25%) rotate(0deg);
  } */
  50% {
    transform: translateX(39%) rotate(0deg);
    /* transform: translateX(76%) translateY(45%) rotate(90deg); */
  }

  100% {
    transform: translate(80%, 45%) rotate(40deg);
    /* transform: translateX(0) rotate(0); */
  }
}
</style>
